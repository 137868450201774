<template>
  <div id="app">
  <mytopbar></mytopbar>
  <router-view></router-view>
  <myfooter></myfooter>
  </div>
</template>

<script>
import MyTopBar from './views/MyTopBar'
import MyFooter from './views/MyFooter'

window.myPos = { lat: 0, lng: 0 };
if ("geolocation" in navigator) {
    const getID = navigator.geolocation.getCurrentPosition(function (position) {
    window.myPos = { lat: position.coords.latitude, lng: position.coords.longitude }
    //console.log("First Pos: ",window.myPos.lng, window.myPos.lat);
    })
  }

export default {
  name: 'app',
  components: {
    'mytopbar': MyTopBar,
    'myfooter': MyFooter
  },
  data () {
    return {
      msg: 'CGB'
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/design.scss';

</style>
