<template>
<footer class="text-muted" id="myfooter">
	<p><a href="http://www.tip-solutions.com">tip-solutions.com</a> &copy; 2024<span v-if="info!=''"> - {{info}}</span>&nbsp;&nbsp;&nbsp;
			<span><a href="mailto:sales@cgb-france.com">sales@cgb-france.com</a></span>
    </p>
</footer>
</template>

<script>
import store from "../store";

export default {
  store: store,
  name: 'myfooter',
  data () {
    return {
    }
    },
  computed: {
    info: function () {
      if (store.state.idUser === 0) return ''
      return store.state.user + ' (' + store.state.idUser + ')   ' + store.state.nomUser + ' (' + store.state.piste + ')   ' + store.state.nomPiste
      },
  },
  }
</script>

<style lang="scss">
@import 'src/assets/scss/design.scss';

footer {
   position:fixed;
   left:0px;
   bottom:0px;
   height:30px;
   width:100%;
   background:#999;
}

.hide {
    display: none;
}

.text-muted {
background-color: $headback-color;
min-height: 40px;
color: $head-color;
padding-left: 10px;
padding-top: 10px;
padding-right: 10px;
}

.text-muted a {
color: $head-color;
text-decoration: none;
}

@media print {
  @page { size: landscape; }
}

.mail {
	height: 50px;
	display:inline;
	padding: 0;
	margin: 0;
}
</style>
