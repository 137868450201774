import store from '../store'
import Vue from 'vue'

let prefix = 'https://www.aero-cgb.fr'
let prefixs2t = 'https://www.aero-cgb.fr'

export const getPrefix = function () {
	return prefix
}

export const getPrefixS2T = function () {
	return prefixs2t
}

export const loginApi = async function (cl, user, pass) {
	return await cl.$http.post(prefix + '/api/user/login/', { user: user, pass: pass })
}

export const logoutApi = async function (cl) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/user/logout/', {}, {headers})
}

export const createImageApi = async function (cl, idPiste) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/data/piste/', { idPiste: idPiste }, {headers})
}

export const executeApi = async function (cl, sql) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/data/execute/', { sql: sql }, {headers})
}

export const selectApi = async function (cl, sql) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/data/select/', { sql: sql }, {headers})
}

export const fileApi = async function (cl, idClient,idFile) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/data/file/', { idClient: idClient, idFile: idFile }, {headers})
}

export const imgApi = async function (cl, idFile) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/data/img/', { idFile: idFile }, {headers})
}

export const paginateApi = async function (cl, sql) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/data/paginate/', { sql: sql }, {headers})
}

export const pageApi = async function (cl, p) {
	let headers = {
		'Content-Type': 'application/json;charset=utf-8',
		'auth-token': store.state.token
	}
	return await cl.$http.post(prefix + '/api/data/page/', { page: p }, {headers})
}
