import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import vmodal from 'vue-js-modal'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

Vue.use(vmodal)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.component("v-select", vSelect)

export const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Code à rajouter dans votre fichier "src/main.js"
import jQuery from 'jquery'
window.jQuery = window.$ = jQuery
require('bootstrap-sass')

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

