import Vue from 'vue'
import Vuex from 'vuex'

function savesession (state) {
  window.sessionStorage.setItem('air-token', state.token)
  window.sessionStorage.setItem('air-idUser', state.idUser)
  window.sessionStorage.setItem('air-typeUser', state.typeUser)
  window.sessionStorage.setItem('air-user', state.user)
  window.sessionStorage.setItem('air-nomuser', state.nomUser)
  window.sessionStorage.setItem('air-piste', state.piste)
  window.sessionStorage.setItem('air-nompiste', state.nomPiste)
  window.sessionStorage.setItem('air-heli', state.helistation)
  window.sessionStorage.setItem('air-zoom', state.zoom)
  window.sessionStorage.setItem('air-center', state.center)
  window.sessionStorage.setItem('air-periode', state.periode)
  window.sessionStorage.setItem('air-logo', state.logo)
}

function loadsession (item, defaut) {
var obj = window.sessionStorage.getItem(item, defaut)
if (typeof (obj) === 'undefined' || obj === null) return defaut
return obj
}

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: loadsession('air-token', ''),
    idUser: loadsession('air-idUser', 0),
    typeUser: loadsession('air-typeUser', 0),
    user: loadsession('air-user', ''),
    nomUser: loadsession('air-nomuser', ''),
    piste: loadsession('air-piste', ''),
    nomPiste: loadsession('air-nompiste', ''),
    helistation: loadsession('air-heli', 0),
    zoom: loadsession('air-zoom', 0),
    center: loadsession('air-center', {}),
    periode: loadsession('air-periode', 6),
    logo: loadsession('air-logo', ''),
    visible: true
  },
  mutations: {
      connexion: (state, data) => {
        state.token = data.token
        state.idUser = data.idUser ? data.idUser : 0
        state.typeUser = data.typeUser ? data.typeUser : 0
        state.user = data.user ? data.user : ''
        state.nomUser = data.nomUser ? data.nomUser : ''
        state.piste = data.piste ? data.piste : 0
        state.nomPiste = data.nomPiste ? data.nomPiste : ''
        state.helistation = data.helistation ? data.helistation : 0
        state.logo = data.logo ? data.logo : ''
        state.periode = data.periode ? data.periode : 6
        state.zoom = 0
        state.center = {}
        state.visible = true
        savesession(state)
      },
      zoom: (state, data) => {
        state.zoom = data.zoom ? data.zoom : 0
        state.center = data.center ? data.center : {}
        savesession(state)
      },
      visible: (state, data) => {
        state.visible = data.visible ? data.visible : true
      }  },
  actions: {},
  getters: {}
})



